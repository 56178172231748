<template>
  <div class="container">
    <!-- 返回头部 -->
    <el-backtop></el-backtop>

    <!-- 头部区域 -->
    <!-- <Header :headerData={} /> -->


    <div class="panel">
      <div class="imgBx">
        <img src="../../assets/img/soil/soil_title.jpg" style="width:100%;" alt=""/>
      </div>
      <div class="imgBx">
          <img src="../../assets/img/soil/figure_1.jpg" style="width:100%;" alt=""/>
      </div>
      <div class="main-title">Introduction</div>
      <p>
        The Shenzhen University - UNESCO Sustainable Ecological Circulation Project Research Center is an interdisciplinary research institution established in 2021. With a strong commitment to the United Nations Sustainable Development Goals (SDGs), the center aims to promote sustainable ecological practices and contribute to the global efforts to achieve a more sustainable future. Led by Professor Y. Martin Lo and his dedicated team, the center conducts comprehensive research, implements innovative solutions, and provides valuable insights in various fields related to sustainable development and ecological conservation.
      </p>
      <p>
        By utilizing an intensive array of sensors and AI wireless Internet of Things (AIoT) technology, coupled with AI large-scale modeling, the research team has developed high-precision digital soil ecological models that can be widely applied to different soil and climate conditions. These models enable the team to effectively understand soil vitality and comprehensively construct a non-toxic and pollution-free sustainable ecosystem. By emulating natural ecological patterns, the ultimate goal is to seek and establish a balance between human beings and the natural environment.
      </p>
      <div class="imgBx">
        <img src="../../assets/img/soil/earthworm.jpg" style="width:100%;" alt=""/>
      </div>
      <div class="main-title">Primary Researcs</div>
      <H3>Ecological Transformation</H3>
      <p>
        Through the implementation of innovative approaches, the center explores methods to transform conventional ecosystems into sustainable and resilient ones. This involves integrating ecological principles and technologies to restore and enhance the ecological functions of degraded areas, promoting biodiversity, and improving ecosystem services.
      </p>
      <div class="imgBx">
        <img src="../../assets/img/soil/vermicomposting.jpg" style="width:100%;" alt=""/>
      </div>
      <H3>Vermicomposting</H3>
      <p>
        The research team focuses on the utilization of earthworms to decompose organic waste and convert it into nutrient-rich vermicompost. This process not only helps in waste management but also enhances soil fertility and promotes sustainable agriculture.
      </p>
      <H3>Ecological Planting</H3>
      <p>
        The center conducts research on ecological planting practices that prioritize native plant species, promote biodiversity, and ensure the sustainable use of natural resources. By studying plant-soil interactions and ecological dynamics, the team aims to develop strategies for efficient and environmentally friendly agriculture and land use.
      </p>
      <div class="imgBx">
        <img src="../../assets/img/soil/ecological.jpg" style="width:100%;" alt=""/>
      </div>        
      <H3>Soil Remediation</H3>
      <p>
        Recognizing the critical role of soil in ecosystem health, the research team investigates innovative techniques for soil remediation, including the removal of pollutants and the restoration of soil structure and fertility. The center aims to develop sustainable approaches to address soil degradation and contamination issues.
      </p>
      <div class="imgBx">
        <img src="../../assets/img/soil/planting.jpg" style="width:100%;" alt=""/>
      </div>
      <p>
        Through its comprehensive research and practical initiatives, the Shenzhen University-UNESCO Sustainable Ecological Circulation Project Research Center strives to contribute to the preservation and restoration of ecosystems. By adopting cutting-edge technologies, such as AIoT and digital modeling, the center aims to provide scientific insights and solutions for sustainable ecological management. Ultimately, the center's work seeks to achieve a harmonious coexistence between humans and the natural environment, fostering a green and sustainable future.
      </p>


      <div class="about_team">
        <H1>About the team</H1>
        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/scientist/Martin.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Professor Y. Martin Lo</div>
            <p>Dr. Y. Martin Lo, decided to step away from his 20 years as a faculty at the University of Maryland in 2014 to focus on helping developing countries. He has organized interdisciplinary teams to tackle food safety and ecological issues in different countries, and was invited to speak on “Healthy Cities” at the United Nations headquarter in NYC during December 2017. A food bioprocess engineer by training at the Ohio State University, Dr. Lo's expertise ranges from intervention for microbial control to recovery of renewable ingredients and assessment of nano-safety. Dr. Lo received the Outstanding Food Safety Research Award from UN Human Health Organization in 2017, and the Presidential Volunteer Service Silver Medals from Presidents Obama and Trump in 2015 and 2016, respectively. He is a Fellow of the World Food Preservation Center, a FDA-recognized Process Authority, the Ambassador of the International Union of Food Science and Technology, and the founding Editor-in-Chief for Food Science & Nutrition.</p>
          </div>         
        </div>

        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/scientist/Tim.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Professor Tim</div>
            <p>Marine biologist. He dedicated to a variety of non-model marine organisms, ranging from foundational taxonomic groups such as sponges and corals to complex biological systems like bryozoans and sea squirts. Dr. Tim is regarded by his colleagues and students as a rigorous yet passionate teacher and marine biologist. He encourages students to cultivate an interest in Mother Nature by experiencing it firsthand rather than solely relying on memorizing textbook content.</p>
          </div>         
        </div>        
        
        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/soil/HuangXinyue.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Xingyue Huang</div>
            <p>Xinyue Huang, the leader of the summer social practice
 program "Composing Waste into Treasures - Organic Composting Leading Ecology"
 at Shenzhen University</p>
          </div>         
        </div>

        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/soil/ChenHao.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Hao Chen</div>
            <p>My name is Chen Hao, from Anhui Province.
 I am an undergraduate student at the Institute for Advanced Study of Shenzhen University.</p>
          </div>         
        </div>
        
        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/soil/ZhuBingying.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Bingying Zhu</div>
            <p>2023 student from Shenzhen University, 
is interested in the field of biological science and 
has a certain research foundation. I hope to exercise my professional skills, 
enhance my personal abilities and literacy, and learn to apply 
knowledge to practice in this practice,
 so as to give back to society.</p>
          </div>         
        </div>        

        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/soil/LingWeiran.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Weiran Ling, </div>
            <p>Student from Shenzhen University, 
is interested in the field of biological science.
 I'm also a member of the summer social practice program. </p>
          </div>         
        </div>     

        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/soil/KongYanyuan.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Yanyuan Kong</div>
            <p>Yanyuan Kong,a student from Shenzhen University,
is keen on both chemistry and biology.
I wish to learn some useful techniques to help farmers in my hometown.</p>
          </div>         
        </div>     

        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/soil/ZhangYining.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Yining Zhang</div>
            <p>My name is Zhang Yining, from the Higher Research Institute of Shenzhen University, and I am a member of the summer social practice team.</p>
          </div>         
        </div>  

        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/soil/XiaoYiqin.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Yiqin Xiao</div>
            <p>member of the summer social practice program "Composing Waste into Treasures - Organic Composting Leading Ecology" at Shenzhen University</p>
          </div>         
        </div>  

        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/soil/DongYuchen.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Dongyu Chen</div>
            <p>2023 student from Shenzhen University, also a member of the program "Composing Waste into Treasures - Organic Composting Leading Ecology"
 </p>
          </div>         
        </div>  

        <div class="img_txt">
          <div class="imgBx">
            <img src="../../assets/img/soil/ZhengShiting.jpg" style="width:100%;" alt=""/>
          </div>
          <div class="txt">
            <div class="title">Shiting Zheng</div>
            <p>I am Zheng Shiting, from the Institute of Advanced Study in SZU.My Eglish name is Kard,and I am a member of the program.
 </p>
          </div>         
        </div>  





        
      </div>



      <el-row justify="center">
        <div class="contact-us">
          <h3>Contact Us</h3>
          <p>Tim Wong: Shenzhen University (SZU)</p>
          <p>timwong@szu.edu.cn</p>
        </div>
      </el-row>
 
    </div>
  
    <!-- 底部 -->
    <!-- <Footer /> -->
  </div>
</template>

<script>
  // import Header from '../../components/Header.vue'
  // import Footer from "../../components/Footer.vue";
 
  export default {
    name: "Home",
    data() {
      return {

      };
    },    
    components: {
      // Footer,
      // Header,
      // SectTurnBook,
    },

    mounted() {
    },

    methods: {
    }
  
  }

</script>


<style lang="less" scoped>
.container {
  margin: 20px 2% auto;
  padding: 10px 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 100px;
  padding-bottom: 0;
}
.imgBx{
  width:100%;
  margin-bottom: 20px;
}
.panel{
  max-width: 1024px;
  margin: 50px auto;
  padding: 0px;
  text-align: justify ;
  line-height: 1.55 ;
  .main-title {    
    text-align: center;  
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0px;
  }
  > p{
    font-size: 14px;
    font-weight: 200;
  }
}
.desc{
  font-size : 14px;
  line-height : 1.55;
}
.sect-desc{
  text-align: left;
  // line-height: 1.75 ;
  padding: 5px 20px;
  font-size: 19px;
  font-weight: 500;
  letter-spacing: 0px;
  // color:#333;
  p::before{
    content: '•';
    margin-right: 5px;
  }
}

.txt-link{
  font-size: 14px;
}
.txt-red{
  color: red;
}

.main {
  color: #333;
  padding: 0 10%;
  // 中区域的左侧文字区
}

.sect-title {
  max-width: 580px;
  text-align: center;
  margin: 24px auto;
  font-size: 26px;
  font-weight: bold;
}
.about_team{
  text-align: center;
}
.img_txt{
    display:-webkit-inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
    .imgBx {
      width : 250px;
      padding : 10px;
      img{
        width : 100%;
        // border: 1px solid red;
        border-radius:50%;
        border-style: 3px solid;
        border-color: darkgray; 
        box-shadow: 0 5px 20px rgba(0,0,0,0.2);
      }
    }
    .txt{
      width : 65%;
      margin-left: 20px;
      text-align: left;
      .title{
        text-align: left;
        font-size: 18px;
        font-weight: 800;
        color:#333;
      }
      >p {
        text-align: left;
        font-size: 16px;
      }
    }
  }

.contact-us {
  line-height: 1.2 ;
  >p {
    font-size: 14px;
  }
}

//显示时按钮样式
.el-button--primary { //需要更改的按钮类型
  background: #2836e0 !important;
  border-color: #2836e0 !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #005eff !important;
  border-color: #005eff !important;
  color: #FFF !important;
}

@media (max-width: 550px) {
  .panel{
    margin: 20px 10px !important;
    h1{
      font-size: 36px !important;
    }
    .img_txt > .txt{
      text-align: center !important;
    }
    // .about_team{
    //   flex: auto !important;
    // }
  }
}
</style>
